import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsageStatsService, Year } from './usagestats.service';
import { UserService } from '../user/user.service';
import { User } from '../user/user';
import { saveAs } from 'file-saver';

@Component({
  templateUrl: 'usagestats.component.html',
  styleUrls: ['usagestats.component.scss'],
})
export class UsageStatsComponent implements OnInit {
  public isEdinaUser: boolean;

  usageStatsForm: FormGroup = this.formBuilder.group({
    year: ['', [Validators.required]],
    hei: ['', [Validators.required, Validators.maxLength(3), Validators.minLength(3)]],
  });

  errorMessage: string;

  academicYears: { text: string; value: number }[];

  userInfo: User;

  selectedYear: string;
  selectedHei: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private usageStatsService: UsageStatsService,
    private userService: UserService
  ) {
    this.usageStatsForm.valueChanges.subscribe(values => {
      this.selectedYear = values.year;
      this.selectedHei = values.hei;
    });
  }

  ngOnInit() {
    this.usageStatsService.getAcademicYears().subscribe(
      (result: Year[]) => {
        this.academicYears = result;
        const lastAcademicYear = this.academicYears[this.academicYears.length - 1].value;
        this.usageStatsForm.controls['year'].setValue(lastAcademicYear);
      },
      (err: string) => {
        // Display error message
        this.onFailed(err);
      }
    );
    this.usageStatsService.isAdminUser().subscribe(
      (result: boolean) => {
        this.isEdinaUser = result;
      },
      (err: string) => {
        // Display error message
        this.onFailed(err);
      }
    );
    this.userService.getUser().subscribe(
      (result: User) => {
        this.userInfo = result;
        this.usageStatsForm.controls['hei'].setValue(this.userInfo.institution.hei);
      },
      (err: string) => {
        // Display error message
        this.onFailed(err);
      }
    );
  }

  onFailed(message: string) {
    console.log('Form could not be build: ', message);

    this.errorMessage = message;
  }

  /**
   * Takes the user back to the home page.
   */
  navigate(): void {
    this.router.navigate(['/']).then(r => console.log('Send user back to home page'));
  }

  /**
   * This method is called on form submission.
   * It returns false to prevent page refresh.
   *
   * @returns false
   * @memberof StatComponent
   */
  generateStats() {
    const year = this.usageStatsForm.controls['year'].value;
    const hei = this.usageStatsForm.controls['hei'].value;
    this.usageStatsService.generateStats(this.usageStatsForm.value).subscribe(
      data => {
        saveAs(data, hei + '_' + year + '_usage-stats.csv');
      },
      err => {
        // Display error message
        this.onFailed(err);
      }
    );
  }
}
