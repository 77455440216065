import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { ActivateCollectionsFormComponent } from '../modules/activatecollections/activate-collections-form.component';
import { ContactFormComponent } from '../modules/contact/contact-form.component';
import { LogoutComponent } from '../pages/logout/logout.component';
import { NgModule } from '@angular/core';
import { NoLocalInfoComponent } from '../pages/nolocalinfo/nolocalinfo.component';
import { NotUserFormComponent } from '../modules/notuser/not-user-form.component';
import { RegisterUserFormComponent } from '../modules/registeruser/register-user-form.component';
import { UpdateUserFormComponent } from '../modules/updateuser/update-user-form.component';
import { VerifyComponent } from '../pages/verify/verify.component';
import { MyCollectionsComponent } from '../modules/collections/my-collections/my-collections.component';
import { SupplierComponent } from '../modules/collections/supplier/supplier.component';
import { OpenComponent } from '../modules/open/open.component';
import { DigimapLayoutComponent } from './digimap-layout.component';
import { LayoutComponent } from '../modules/collections/layout.component';
import { HomeComponent } from '../modules/collections/home.component';
import { CollectionDetailComponent } from '../modules/collections/collection-detail.component';
import { WMSLinksComponent } from '../modules/collections/wms-links/wms-links.component';
import { SubscriptionsComponent } from '../pages/subscriptions/subscriptions.component';
import { CookiePolicyComponent } from '../pages/cookies-policy/cookie-policy.component';
import { PwLoginComponent } from '../pages/login/pw-login.component';
import { AddressBaseFormComponent } from '../modules/addressbase/address-base-form.component';
import { UsageStatsComponent } from '../modules/usagestats/usagestats.component';

/*
 * IMPORTANT: If any URLs here are modified or added, two other files also need to be
 * checked and/or updated:
 *  - The addViewControllers method in WebApp.java
 *  - Endpoints need to be added to one of lists in AuthConfig.java (there are two lists
 *    for protected and non-protected routes)
 */

export const collectionRoutes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'os', component: CollectionDetailComponent },
      { path: 'historic', component: CollectionDetailComponent },
      { path: 'geology', component: CollectionDetailComponent },
      { path: 'environment', component: CollectionDetailComponent },
      { path: 'marine', component: CollectionDetailComponent },
      { path: 'aerial', component: CollectionDetailComponent },
      { path: 'lidar', component: CollectionDetailComponent },
      { path: 'global', component: CollectionDetailComponent },
      { path: 'society', component: CollectionDetailComponent },
      { path: 'whats-new', component: CollectionDetailComponent },
      { path: 'pilot', component: CollectionDetailComponent },
      { path: 'agcensus', component: CollectionDetailComponent },
      { path: 'verisk', component: CollectionDetailComponent },
      { path: 'os/wms', component: WMSLinksComponent },
      { path: 'historic/wms', component: WMSLinksComponent },
      { path: 'geology/wms', component: WMSLinksComponent },
      { path: 'verisk/wms', component: WMSLinksComponent },
      { path: 'environment/wms', component: WMSLinksComponent },
      { path: 'marine/wms', component: WMSLinksComponent },
      { path: 'chart/wms', component: WMSLinksComponent },
      { path: 'aerial/wms', component: WMSLinksComponent },
    ],
  },
];

export const appRoutes: Routes = [
  { path: 'logout', component: LogoutComponent },
  { path: 'update', component: UpdateUserFormComponent },
  { path: 'register', component: RegisterUserFormComponent },
  { path: 'collections', component: MyCollectionsComponent },
  { path: 'supplier', component: SupplierComponent },
  { path: 'activate', component: ActivateCollectionsFormComponent },
  { path: 'notuser', component: NotUserFormComponent },
  { path: 'verify/:token', component: VerifyComponent },
  { path: 'update/verify/:token', component: UpdateUserFormComponent },
  { path: 'verify', component: VerifyComponent },
  { path: 'nolocalinfo', component: NoLocalInfoComponent },
  { path: 'contact', component: ContactFormComponent },
  { path: 'subscription', component: SubscriptionsComponent },
  { path: 'stats', component: UsageStatsComponent },
  { path: 'cookie-policy', component: CookiePolicyComponent },
  { path: 'pw-login', component: PwLoginComponent },
  { path: 'addressbase', component: AddressBaseFormComponent },
];

// Required to get router fragments working
const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  relativeLinkResolution: 'legacy',
};

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        // Note: collection routes must appear first, so that HomeComponent's '/' route will be used
        { path: '', component: DigimapLayoutComponent, children: collectionRoutes },
        { path: '', component: DigimapLayoutComponent, children: appRoutes },
        { path: 'open', component: OpenComponent },
      ],
      routerOptions
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
