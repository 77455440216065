<main class="row no-gutters">
  <div class="col-lg-offset-1 col-lg-10 dm-viewport">
    <!-- Error view, the message retrieved from the server -->
    <div *ngIf="errorMessage">
      <div class="alert alert-danger" tabindex="0">
        <p><strong>Error!</strong> The following problem has occured:</p>
        <ul>
          <li>{{ errorMessage }}</li>
        </ul>
      </div>
      <p tabindex="0">
        If the problem persists, please contact the EDINA Helpdesk at
        <a href="mailto:digimap@ed.ac.uk">digimap@ed.ac.uk</a>
        to resolve this problem.
      </p>
      <button type="button" class="btn btn-success" (click)="navigate()">Continue</button>
    </div>

    <div *ngIf="!errorMessage">
      <h3 tabindex="0">Digimap site usage statistics</h3>
      <p>
        Select the academic year and generate a CSV file of usage statistics for your institution. If you require any
        figures not listed here, please contact us at
        <a href="mailto:edina@ed.ac.uk">edina@ed.ac.uk</a>.
      </p>
      <mat-card class="panel-color">
        <form [formGroup]="usageStatsForm" novalidate ngNoForm method="post" (ngSubmit)="generateStats()">
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label for="year">Academic Year:</label>
                <select id="year" name="year" class="form-control year-form-control" formControlName="year">
                  <option *ngFor="let academicYear of academicYears" [value]="academicYear.value">
                    {{ academicYear.text }}
                  </option>
                </select>
              </div>

              <!-- Hei parameter only Editable for Edina User -->
              <div class="row" [hidden]="!isEdinaUser">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="year"
                      >EDINA users can choose which institution to query. Enter hei code to choose a different
                      institution:</label
                    >
                    <input id="hei" name="hei" formControlName="hei" type="text" class="form-control" />
                    <span
                      *ngIf="isEdinaUser && usageStatsForm.controls['hei'].hasError('minlength')"
                      class="help-block help-block-error"
                      >Hei code is Too Short!</span
                    >
                    <span
                      *ngIf="isEdinaUser && usageStatsForm.controls['hei'].hasError('maxlength')"
                      class="help-block help-block-error"
                      >Hei code is Too Long!</span
                    >
                  </div>
                </div>
              </div>

              <div class="form-group">
                <p>
                  These usage statistics offer the number of sessions, data downloads, screen maps, print maps and WMS
                  map requests for your institution. Usage is given per month for each academic year, and further by
                  registered department.
                </p>
                <ol>
                  <li>
                    <strong>Unique users</strong> - the number of unique users who have used used a particular
                    collection. Similar to sessions, some collections such as NGD don't have a number for this since
                    they are licensed as standalone products but accessed from within a different Collection.
                  </li>
                  <li>
                    <strong>Sessions</strong> - this is the number of times a user accesses a particular collection. The
                    National Tree Map, NGD, Aerial Extra and LandClan data collections do not have a number of sessions
                    because these datasets are accessed within another Collection. e.g. Aerial Extra data is accessed
                    from within the Aerial Digimap Collection. Sessions are given for the top level Collection. Number
                    of sessions for these data products is given as N/A.
                  </li>
                  <li>
                    <strong>Number of downloads</strong> - this measures the number of data products actually
                    downloaded. If a user requests data but never retrieves it from Digimap, it will not be counted in
                    these figures. If a user requests one data order containing three data products and downloads all
                    three, this number will record three data downloads, one against each product downloaded. If data is
                    ordered but not downloaded, no record is included in these numbers.
                  </li>
                  <li>
                    <strong>Number of screen maps</strong> - this records the number of maps created on the screen.
                    Panning the map by more than a certain distance will log as a new map and add to this number.
                  </li>
                  <li>
                    <strong>Number of print maps</strong> - records the number of printable maps generated using the
                    print dialogue. These can be PDF, PNG or JPG files. Note that we cannot count whether or not the map
                    is sent to a printer.
                  </li>
                  <li>
                    <strong>Number of WMS maps</strong> - users can request backdrop mapping using the Digimap WMS
                    feeds. This figure records the number of WMS requests made for maps using this method. Not every
                    data product is available via WMS.
                  </li>
                  <li>
                    <strong>Department</strong> may change over time. The department names listed are those given
                    against a user registration. You may see a department in this list that no longer exists within your
                    institution. It will remain in place while there are active users who registered with Digimap and
                    listed that department against their registration. We cannot retrospectively update departments that
                    are already assigned to registered users.
                  </li>
                </ol>
              </div>

              <div class="form-group">
                <button type="submit" class="btn btn-lg btn-success" [disabled]="!usageStatsForm.valid">
                  Download
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</main>
