import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
// import { saveAs } from 'file-saver';

const API = {
  getAcademicYears: '/api/stats/get-academic-years',
  isEdinaUser: '/api/stats/is-edina-user',
  generateStats: '/api/stats/generate-stats',
};

export interface Year {
  text: string;
  value: number;
}

@Injectable()
export class UsageStatsService {
  constructor(private http: HttpClient) {}

  getAcademicYears(): Observable<Year[]> {
    return this.http
      .get<Year[]>(API.getAcademicYears)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error.error.message || 'Server error')));
  }

  isAdminUser(): Observable<boolean> {
    return this.http
      .get<boolean>(API.isEdinaUser)
      .pipe(catchError((error: HttpErrorResponse) => throwError(error.error.message || 'Server error')));
  }

  generateStats(data: AbstractControl): Observable<any> {
    return this.http.post(API.generateStats, data, { responseType: 'blob' });
  }
}
