import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { UsageStatsService } from './usagestats.service';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatButtonModule, MatCardModule],
  providers: [UsageStatsService],
})
export class UsagestatsModule {}
