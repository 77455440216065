// Ensure Bootstrap 3 JavaScript is included.
import 'jquery';
import 'bootstrap-sass';

import { ActivateCollectionsModule } from '../modules/activatecollections/activate-collections.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { BrowserModule } from '@angular/platform-browser';
import { CollectionService } from '../modules/collections/collection.service';
import { CollectionsModule } from '../modules/collections/collections.module';
import { ConfigService } from '../modules/config/config.service';
import { ControlMessagesModule } from '../modules/controlmessages/control-messages.module';
import { DmCommonModule } from '../commons/dm-common.module';
import { ContactModule } from '../modules/contact/contact.module';
import { HttpClientModule } from '@angular/common/http';
import { LogoutComponent } from '../pages/logout/logout.component';
import { NgModule } from '@angular/core';
import { NoLocalInfoComponent } from '../pages/nolocalinfo/nolocalinfo.component';
import { NotUserFormModule } from '../modules/notuser/not-user-form.module';
import { NotificationsModule } from '../modules/notifications/notifications.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RegisterUserModule } from '../modules/registeruser/register-user.module';
import { SvgModule } from '../modules/svg/svg.module';
import { TemplateModule } from '../modules/template/template.module';
import { UpdateUserModule } from '../modules/updateuser/update-user.module';
import { UserService } from '../modules/user/user.service';
import { VerifyComponent } from '../pages/verify/verify.component';
import { VerifyService } from '../pages/verify/verify.service';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';
import { OpenModule } from '../modules/open/open.module';
import { DigimapLayoutComponent } from './digimap-layout.component';
import { SubscriptionsComponent } from '../pages/subscriptions/subscriptions.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookiePolicyComponent } from '../pages/cookies-policy/cookie-policy.component';
import { PwLoginComponent } from '../pages/login/pw-login.component';
import { AddressBaseModule } from '../modules/addressbase/address-base.module';
import { UsagestatsModule } from '../modules/usagestats/usagestats.module';
import { UsageStatsComponent } from '../modules/usagestats/usagestats.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

if (environment.production && window.location.host === 'digimap.edina.ac.uk') {
  Sentry.init({
    dsn: 'https://daee3fa876fa42c58ff8b5b0d8415821@sentry.edina.ac.uk/7',
    environment: window.location.host,
    release: environment.versionInfo.revision,
  });
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NotificationsModule,
    SvgModule,
    TemplateModule,
    ControlMessagesModule,
    CollectionsModule,
    NotUserFormModule,
    ActivateCollectionsModule,
    DmCommonModule,
    UpdateUserModule,
    RegisterUserModule,
    ContactModule,
    OpenModule,
    MatTabsModule,
    BrowserAnimationsModule,
    AddressBaseModule,
    UsagestatsModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
  ],
  declarations: [
    AppComponent,
    DigimapLayoutComponent,
    LogoutComponent,
    VerifyComponent,
    NoLocalInfoComponent,
    SubscriptionsComponent,
    UsageStatsComponent,
    CookiePolicyComponent,
    PwLoginComponent,
  ],
  providers: [UserService, ConfigService, VerifyService, CollectionService],
  bootstrap: [AppComponent],
})
export class AppModule {}
